import { useMutation, useQuery } from "@tanstack/vue-query";
import { clone, omit } from "radash";

interface Session {
  notary_details_id: string;
  date_time: string;
  docs_num: string;
  session_status: string;
  apt_duration: number;
  clients: string;
  notaries: string;
}

export const useSession = defineStore("session", () => {
  const route = useRoute();
  const { $axios, $reportError } = useNuxtApp();

  const model_id = ref("");
  const session = ref<any>(null);
  const create = ref({});
  const clients = ref([]);
  const associates = ref([]);
  const witnesses = ref([]);
  const users = ref([]);
  const metadata = ref({});

  const { mutate: updateMeta } = useMutation({
    mutationFn: async (data: any) => {
      return await $axios
        .put(`/v1/sessions/${route.params.id}`, {
          ...metadata.value,
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      $reportError("Session Store Error", error);
    },
  });

  const { data, refetch, isFetching, suspense } = useQuery({
    queryKey: ["session", route.params.id],
    queryFn: async () => {
      return await $axios.get(`/v1/sessions/${route.params.id}`).then((res) => {
        session.value = clone(
          omit(res.data, [
            "witnesses",
            "associates",
            "clients",
            "users",
            "metadata",
          ]),
        );
        witnesses.value = res.data.witnesses;
        associates.value = res.data.associates;
        clients.value = res.data.clients;
        users.value = res.data.users;
        model_id.value = route.params.user_id as string;
        metadata.value = res.data.metadata;
        return res.data;
      });
    },
    enabled: computed(() => Boolean(route.params.id)),
  });

  return {
    updateMeta,
    model_id,
    session,
    clients,
    create,
    associates,
    data,
    witnesses,
    users,
    metadata,
    refetch,
    suspense,
    isFetching,
  };
});
